<template>
  <v-app id="inspire">
    <div class="app-context">
      <v-app-bar
        absolute
        color="#fcb69f"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <div class="logo" style="margin: auto; width: 1000px">
          <v-row>
            <v-col cols="12" md="2"
              ><img class="header_logo" src="@/assets/images/logo_default.svg"
            /></v-col>
            <v-col cols="12" md="8"
              ><h1
                style="
                  font-size: 32px;
                  position: relative;
                  top: 3px;
                  margin-left: 20px;
                "
              >
                유사증상정보 상세
              </h1></v-col
            >
          </v-row>
        </div>

        <v-spacer></v-spacer>
      </v-app-bar>
      <div style="margin: 150px 20px 0 20px">
        <div style="position: relative; width: 80%; left: 8%">
          <div
            style="
              width: 100%;
              text-align: center;
              font-size: 32px;
              margin: 10px;
            "
          >
            <span>유사증상정보 상세</span>
          </div>
          <v-container>
            <div class="row">
              <div>
                <v-textarea
                  class="prodDtl"
                  outlined
                  name="input-7-4"
                  label="유사증상정보 입력하세요(각 정보 사이마다 띄어쓰기를 꼭 해주세요)"
                  height="200"
                  style="width: 700px"
                  v-model="symptomParam.symptomGrp"
                ></v-textarea>
              </div>
            </div>
            <div>
              <div class="row" style="margin-top: 40px">
                <div
                  style="
                    width: 100%;
                    text-align: center;
                    font-size: 32px;
                    margin: 10px;
                  "
                >
                  <v-btn
                    class="primary white--text"
                    outlined
                    tile
                    dense
                    large
                    style="margin: 0 50px 0 -70px"
                    @click="modifySymptom()"
                    ><v-icon style="margin-right: 15px">mdi-update</v-icon>
                    <span style="font-size: 20px; line-height: 10px"
                      >수정 하기</span
                    >
                  </v-btn>
                  <v-btn
                    class="error white--text"
                    outlined
                    tile
                    dense
                    large
                    @click="deleteSymptom()"
                    id="focusControl"
                    ><v-icon style="margin-right: 15px">mdi-delete</v-icon>
                    <span style="font-size: 20px; line-height: 10px"
                      >삭제 하기</span
                    >
                  </v-btn>
                </div>
              </div>
            </div>
          </v-container>
          <div style="height: 100px" />
        </div>
      </div>
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import DefaultPopup from "@/components/modal/DefaultPopup";
export default {
  components: { DefaultPopup },
  data() {
    return {
      symptomParam: {
        symptomGrp: "",
      },
      popupSet: {},
    };
  },
  created() {
    //this.getBasicData();
    //this.getPromotionData();
    this.getSymptom();
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapGetters("admin", ["GET_SYMPTOM_DATA"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapActions("join", ["FETCH_BASIC_TARGET_UN_AUTH"]),
    ...mapActions("promotion", ["FETCH_PROMOTION_TARGET_UN_AUTH"]),
    ...mapActions("admin", ["BRING_SYMPTOM_DATA", "UPDATE_SYMPTOM_DATA"]),
    async getSymptom() {
      //console.log("this.$route.params.code : ",this.$route.params.code);

      if (
        this.$route.params.code === null ||
        this.$route.params.code === undefined
      )
        this.$route.params.code = "";

      var symptomBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

      if (!symptomBoo.test(this.$route.params.code)) {
        this.SET_POPUP(true);
        this.popupSet.title = "존재하지 않는 유사증상번호";
        this.popupSet.content = "존재하지 않는 유사증상번호입니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      await this.BRING_SYMPTOM_DATA(this.$route.params.code).then(() => {
        this.symptomParam = this.GET_SYMPTOM_DATA;
      });
      //console.log("symptomParam",this.symptomParam);
    },
    async modifySymptom() {
      //console.log(this.symptomParam.symptomGrp);

      //마지막 공백들 제거
      while (
        this.symptomParam.symptomGrp.substring(
          this.symptomParam.symptomGrp.length - 1,
          this.symptomParam.symptomGrp.length
        ) === " "
      ) {
        this.symptomParam.symptomGrp = this.symptomParam.symptomGrp.substring(
          0,
          this.symptomParam.symptomGrp.length - 1
        );
      }

      //유효성 검사
      if (
        this.symptomParam.symptomGrp === null ||
        this.symptomParam.symptomGrp === undefined ||
        this.symptomParam.symptomGrp === ""
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "유사증상군 확인";
        this.popupSet.content = "유사증상군이 입력되지 않았습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //유사증상군 중복된 증상이 있는지 검사
      let count = 0;
      let duplicatedSymptoms = "";

      const symptoms = this.symptomParam.symptomGrp.split(" ");
      for (let i = 0; i < symptoms.length; i++) {
        for (let j = 0; j < symptoms.length; j++) {
          if (i !== j && symptoms[i] === symptoms[j]) {
            count++;
            duplicatedSymptoms += symptoms[i] + ", ";
          }
        }
      }
      if (count > 0) {
        this.SET_POPUP(true);
        this.popupSet.title = "유사증상군 중복";
        this.popupSet.content = `유사증상군이 ${count}개 중복되었으며, 
          중복된 증상군들은 [ ${duplicatedSymptoms.substring(
            0,
            duplicatedSymptoms.length - 2
          )} ] 입니다.`;
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      try {
        const transedPrParam = JSON.stringify(this.symptomParam);
        const transData = new FormData();
        transData.append("symptomParam", transedPrParam);
        await this.UPDATE_SYMPTOM_DATA(transData).then((data) => {
          if (data === "이상없음") {
            this.SET_POPUP(true);
            this.popupSet.title = "유사증상정보 수정 완료";
            this.popupSet.content = "유사증상정보를 성공적으로 수정하였습니다.";
            this.popupSet.popType = "custom";
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.destination = true;
            this.popupSet.nextLink = "/v/adminMain/11";
          } else {
            this.SET_POPUP(true);
            this.popupSet.title = "유사증상정보 수정 실패";
            this.popupSet.content = data;
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        });
      } catch (error) {
        //console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "유사증상정보 수정 실패";
          this.popupSet.content = "유사증상정보를 수정할 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    deleteSymptom() {
      //console.log("this.symptomParam : " ,this.symptomParam);
      this.SET_POPUP(true);
      this.popupSet.title = "유사증상정보 폐기";
      this.popupSet.content =
        "유사증상정보를 폐기하시겠습니까? \n 확인 버튼을 누르면 삭제 및 리스트 페이지로 이동됩니다.";
      this.popupSet.popType = "custom_symptom_delete";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.destination = true;
      this.popupSet.symptomParam = this.symptomParam;
      this.popupSet.nextLink = "/v/adminMain/11";
    },
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style>
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
.add-btn {
  position: relative;
  left: -80px;
}
</style>
